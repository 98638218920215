<template>
    <div class="forgetpw">
         <a-modal
            v-model="loginDialog"
            centered
            :footer="null"
            :width="1000"
            class="loginDialog"
            :destroyOnClose="true"
            :closable="false"
        >
        
        </a-modal>
    </div>
</template>
<script>
export default {
    data(){
        return {
            loginDialog:tr
        }
    }
}
</script>